<template>
  <form @submit.prevent.stop>
    <div class="vx-row mb-3">
      <div class="vx-col w-full md:w-1/2">
        <img
          src="@/assets/img/reviews/google-my-business-logo.svg"
          alt="Google my business"
          class="mx-auto"
          width="auto"
          height="120">

        <p class="text-center mt-base">
          {{ $t('$ReviewsSettingsModule.$BusinessTab.AutocompleteLabel') }}
        </p>

        <GmapAutocomplete
          :value="autocompleteDescription"
          :placeholder="$t('$ReviewsSettingsModule.$BusinessTab.AutocompletePlaceholder')"
          class="vs-inputx vs-input--input normal mt-3"
          :bounds="autocompleteBounds"
          @place_changed="onPlaceSelected">
        </GmapAutocomplete>

        <p class="text-center mt-base">
          {{ $t('$ReviewsSettingsModule.$BusinessTab.IsYourBusinessMissing') }}
        </p>

        <div class="text-center mt-3">
          <a
            href="https://business.google.com/create"
            target="_blank"
            class="link-contextual">
            {{ $t('$ReviewsSettingsModule.$BusinessTab.CreateGoogleListing') }}
          </a>
        </div>

        <p class="mt-5 text-center">
          <small class="font-bold">
            {{ $t('$ReviewsSettingsModule.$BusinessTab.CreateGoogleListingMsg') }}
          </small>
        </p>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <vs-divider class="block md:hidden my-base"/>
        <div class="mt-5">
          <small class="font-bold">
            {{ $t('$ReviewsSettingsModule.$BusinessTab.MapLabel') }}
          </small>

          <GmapMap
            ref="mapRef"
            style="width: 100%; height: 350px;"
            :zoom="zoom"
            :center="mapCenter"
            :options="{
              zoomControl: true,
              scaleControl: true,
              mapTypeControl: false,
              fullscreenControl: false,
              streetViewControl: false,
              disableDefaultUi: true
            }"
            @click="onMapClicked"
            @center_changed="updateCenter"
            @idle="sync">
            <gmap-info-window
              :options="infoWindowOptions"
              :position="infoWindowPos"
              :opened="infoWindowOpen"
              @closeclick="infoWindowOpen=false">
            </gmap-info-window>
            <GmapMarker
              v-if="placeLatLng"
              :position="placeLatLng"
              clickable
              @click="onMarkClicked"/>
          </GmapMap>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import { gmapApi } from 'gmap-vue';
import allCountries from '@/assets/utils/all-countries';

/**
 * Component to select business in review settings
 *
 * @module views/modules/reviews/ReviewsSettingsCreateOrEditBusinessTab
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {string} [autocompleteDescription=''] - autocomplete description
 * @vue-data {Object | null} [placeLatLng=null] - latitude and longitude of selected place
 * @vue-data {string} [placeId=''] - selected place id
 * @vue-data {Object} [reportedMapCenter={...}] - reported map center of google-map
 * @vue-data {Object} [mapCenter={...}] - real map center of google-map
 * @vue-data {Object} [zoom=1.8] - zoom of google-map
 * @vue-data {Object | null} [infoWindowPos=null] - lat and lng of info windows in google-map
 * @vue-data {boolean} [infoWindowOpen=false] - indicate if info window is open or no
 * @vue-data {Object} [infoWindowOptions={...}] - info window options
 * @vue-data {Object[]} [countryOptions=[...]] - country options
 * @vue-computed {Object} autocompleteBounds - autocomplete bounds for queries
 * @vue-computed {Object} google - google maps api
 * @vue-event {void} initGoogleMap - init the google map
 * @vue-event {void} setPlaceIdByBusinessAddress - set place id by business address
 * @vue-event {void} setMapCenterByGeolocation - set map center by geolocation
 * @vue-event {void} setAutocompleteDescriptionAndMapMarkByPlaceId -
 * set autocomplete and map mark by place id
 * @vue-event {void} setAutocompleteDescriptionByPlaceId - set autocomplete by place id
 * @vue-event {void} updateCenter - call on update map center
 * @vue-event {void} sync - sync real map center with reported map center
 * @vue-event {void} onMapClicked - call on map clicked to set infoWindow and autocomplete
 * @vue-event {void} onPlaceSelected - call autocomplete selected to set map mark
 * @vue-event {void} setInfoWindow - set info window in google-map
 * @vue-event {void} onPlaceUpdated - called on place updated to emit place data
 */
export default {
  name: 'ReviewsSettingsCreateOrEditBusinessTab',
  data() {
    return {
      autocompleteDescription: '',
      placeLatLng: null,
      placeId: '',
      placeName: '',
      placeAddress: '',
      placeRating: 0,
      reportedMapCenter: {
        lat: 30,
        lng: 0,
      },
      mapCenter: {
        lat: 30,
        lng: 0,
      },
      zoom: 1.8,

      infoWindowPos: null,
      infoWindowOpen: false,
      infoWindowOptions: {
        content: '',
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      countryOptions: allCountries,
    };
  },
  computed: {
    ...mapGetters({
      getTenantFlag: 'auth/getTenantFlag',
      reviewsSettings: 'auth/tenantAccountSettingsReviews',
      accountDetails: 'auth/tenantAccountDetails',
    }),
    autocompleteBounds() {
      return {
        north: this.mapCenter.lat + 0.1,
        south: this.mapCenter.lat - 0.1,
        east: this.mapCenter.lng + 0.1,
        west: this.mapCenter.lng - 0.1,
      };
    },
    google: gmapApi,
  },
  created() {
    if (this.getTenantFlag('completedReviewsSettings') && this.reviewsSettings.placeId) {
      this.placeId = this.reviewsSettings.placeId;
    }
  },
  mounted() {
    this.initGoogleMap();
  },
  methods: {
    async initGoogleMap() {
      this.$vs.loading({ type: 'radius' });

      if (this.placeId) {
        await this.setAutocompleteDescriptionAndMapMarkByPlaceId();
        this.onPlaceUpdated();
      } else if (this.getTenantFlag('completedAccountDetails')
        && this.accountDetails && this.accountDetails.name
        && this.accountDetails.country && this.accountDetails.state
        && this.accountDetails.city && this.accountDetails.address) {
        try {
          await this.setPlaceIdByBusinessAddress();
          this.onPlaceUpdated();
        } catch (e) {
          await this.setMapCenterByGeolocation();
        }
      } else {
        await this.setMapCenterByGeolocation();
      }

      this.$vs.loading.close();
      this.sync();
    },
    async setPlaceIdByBusinessAddress() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        if (!(this.accountDetails && this.accountDetails.name
          && this.accountDetails.country && this.accountDetails.state
          && this.accountDetails.city && this.accountDetails.address)) {
          reject(new Error('There is no valid business details'));
        }

        const businessCountry = this.countryOptions.find(
          (country) => country.iso2 === this.accountDetails.country,
        );

        if (!(businessCountry && businessCountry.name)) {
          reject(new Error('There is no valid business country'));
        }

        this.autocompleteDescription = `${this.accountDetails.name}, ${this.accountDetails.address}, ${this.accountDetails.city}, ${this.accountDetails.state}, ${businessCountry.name}`;

        const map = await this.$refs.mapRef.$mapPromise;
        const placesService = new this.google.maps.places.PlacesService(map);
        const request = {
          query: this.autocompleteDescription,
          fields: ['name', 'formatted_address', 'geometry', 'place_id'],
        };

        placesService.findPlaceFromQuery(request, (results, status) => {
          if (status === this.google.maps.places.PlacesServiceStatus.OK && results) {
            this.reportedMapCenter = {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            };
            this.placeLatLng = { ...this.reportedMapCenter };
            this.setInfoWindow(results[0].name, results[0].formatted_address);
            this.infoWindowOpen = true;
            this.placeId = results[0].place_id;
            this.placeName = results[0].name;
            this.placeAddress = results[0].formatted_address;
            this.placeRating = results[0].rating;
            this.zoom = 15;
            this.sync();
            resolve();
          } else {
            reject(new Error('Error search place'));
          }
        });
      });
    },
    async setMapCenterByGeolocation() {
      if (navigator.geolocation) {
        return new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              this.reportedMapCenter = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              this.zoom = 12;
              resolve();
            },
            (e) => {
              reject(e);
            },
            {
              enableHighAccuracy: true,
              timeout: 5000,
              maximumAge: 0,
            },
          );
        });
      }

      console.log('Browser doesn\'t support getGeolocation');
      return '';
    },
    async setAutocompleteDescriptionAndMapMarkByPlaceId() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        if (!this.placeId) {
          reject(new Error('There is no valid place id'));
        }

        const map = await this.$refs.mapRef.$mapPromise;
        const placesService = new this.google.maps.places.PlacesService(map);
        const request = {
          placeId: this.placeId,
          fields: ['name', 'formatted_address', 'geometry', 'rating'],
        };

        placesService.getDetails(request, (place, status) => {
          if (
            status === this.google.maps.places.PlacesServiceStatus.OK
            && place
            && place.name
            && place.formatted_address
            && place.geometry
          ) {
            this.placeName = place.name;
            this.placeAddress = place.formatted_address;
            this.placeRating = place.rating;
            this.autocompleteDescription = `${place.name}, ${place.formatted_address}`;
            this.reportedMapCenter = {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            };
            this.placeLatLng = { ...this.reportedMapCenter };
            this.setInfoWindow(place.name, place.formatted_address);
            this.infoWindowOpen = true;
            this.zoom = 15;
            this.sync();
            resolve();
          } else {
            reject(new Error('Error to get place details'));
          }
        });
      });
    },
    async setAutocompleteDescriptionByPlaceId() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        if (!this.placeId) {
          reject(new Error('There is no valid place id'));
        }

        const map = await this.$refs.mapRef.$mapPromise;
        const placesService = new this.google.maps.places.PlacesService(map);
        const request = {
          placeId: this.placeId,
          fields: ['name', 'formatted_address', 'rating'],
        };

        placesService.getDetails(request, (place, status) => {
          if (
            status === this.google.maps.places.PlacesServiceStatus.OK
            && place
            && place.name
            && place.formatted_address
          ) {
            this.placeName = place.name;
            this.placeAddress = place.formatted_address;
            this.placeRating = place.rating;
            this.autocompleteDescription = `${place.name}, ${place.formatted_address}`;
            resolve();
          } else {
            reject(new Error('Error to get place details'));
          }
        });
      });
    },
    updateCenter(latLng) {
      this.reportedMapCenter = {
        lat: latLng.lat(),
        lng: latLng.lng(),
      };
    },
    sync() {
      this.mapCenter = this.reportedMapCenter;
    },
    async onMapClicked(e) {
      this.placeLatLng = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      };
      this.placeId = e.placeId;
      this.infoWindowOpen = false;
      this.zoom = 15;
      await this.setAutocompleteDescriptionByPlaceId();
      this.onPlaceUpdated();
    },
    onMarkClicked(e) {
      this.setAutocompleteDescriptionByPlaceId();
      this.onPlaceUpdated();
    },
    onPlaceSelected(place) {
      this.placeLatLng = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      this.reportedMapCenter = { ...this.placeLatLng };
      this.setInfoWindow(place.name, place.formatted_address);
      this.autocompleteDescription = `${place.name}, ${place.formatted_address}`;
      this.infoWindowOpen = true;
      this.placeId = place.place_id;
      this.placeName = place.name;
      this.placeAddress = place.formatted_address;
      this.placeRating = place.rating;
      this.zoom = 15;
      this.sync();
      this.onPlaceUpdated();
    },
    setInfoWindow(placeName, placeAddress) {
      this.infoWindowPos = { ...this.placeLatLng };
      this.infoWindowOptions.content = `
        <p><strong>${placeName}</strong></p>
        <span>${placeAddress}</span>
      `;
    },
    onPlaceUpdated() {
      this.$emit('place-updated', {
        id: this.placeId,
        name: this.placeName,
        address: this.placeAddress,
        rating: this.placeRating,
      });
    },
  },
};
</script>
